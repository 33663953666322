html,
body {
  font-size: 17px;
}

body {
  margin: 0;
  font-family: "DM Serif Text", "Helvetica", sans-serif, -apple-system,
    BlinkMacSystemFont;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: radial-gradient(#dadada 1px, #fff 1px);
  background-size: 20px 20px;
  color: #3c3c41;
}

.dark-theme {
  margin: 0;
  font-family: "DM Serif Text", "Helvetica", sans-serif, -apple-system,
    BlinkMacSystemFont;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: radial-gradient(#3e3e3e 1px, transparent 1px);
  background-size: 20px 20px;
  background-color: #000;
  color: #e0e0e7 !important;
}

.soccer-theme {
  margin: 0;
  font-family: "DM Serif Text", "Helvetica", sans-serif, -apple-system,
    BlinkMacSystemFont !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: radial-gradient(#6d6d6d 1px, transparent 1px);
  background-size: 20px 20px;
  background-color: #09140c;
  color: #fff !important;
}

.ReactModal__Overlay--after-open {
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.75) !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
